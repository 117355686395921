.header {
  width: 100%;
  height: auto;
  background-color: #257ba3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 10%;
}

.logo {
  font-family: 'Sofia Sans', 'Lato', sans-serif;
  /* font-size: 2rem; */
  font-weight: bold;
  color: white;
  margin: 0 1rem 0 0;
}

.logo img {
  max-height: 50px;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 2rem 0 0;
}

.header a:link, .header a:visited {
  font-size: 1.1rem;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.header a:hover,
.header a.active {
  color: #98d3ee;
}

.header button:hover {
  background-color: #add6e9;
  color: #257ba3;
}

@media only screen and (max-width: 600px) {
  .logo {
    margin:0;
  }

  .header {
    flex-direction: column;
  }

  .header ul {
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
  }

  .header li {
    margin: 0.5rem 0;
  }
}