.auth {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: #00406b;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    color: white;
  }
  
  .auth h1 {
    text-align: center;
    color: white;
    margin: 1.5rem 0;
  }

  .auth .error-message {
    color: #81cbee;
    margin-top: 0;
  }
  
  .auth .error-input {
    background-color: #afdcf0;
    border: 1px solid #ffffff;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    font: inherit;
    background-color: #f0f7fa;
    color: #2e8cb8;
    border-radius: 4px;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }

  .action {
    margin-top: 1.5rem;
  }

  .action button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: #1a7caa;
  color: white;
  border: 1px solid #257ba3;
}

.action button:hover {
  background-color: #3689af;
  border-color: #257ba3;
}