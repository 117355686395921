.card-wrapper {
    width: 98% auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #f0f7fa;
    padding:1rem 0;
    margin:1rem 0;
    border: 1px solid #c1dfec;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
    text-align: center;
}

.card-wrapper .data-piece {
    width: 25%;
    margin: 0.3rem 0;
}

.card-wrapper .data-piece span {
    display: block;
    padding: 0.3rem;
}

.card-wrapper .data-piece span.label {
    font-weight: bold;
    color: #066591;
}

.dates {
    font-size: 0.9em;
    color: #333333;
}

@media only screen and (max-width: 600px) {
    .card-wrapper .data-piece {
        min-width:12em;
    }
}